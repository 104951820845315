import request from '@/utils/request'


// 查询视频-类型(新增)列表
export function listTagtype(query) {
  return request({
    url: '/platform/video-type/list',
    method: 'get',
    params: query
  })
}

// 查询视频-类型(新增)分页
export function pageTagtype(query) {
  return request({
    url: '/platform/video-type/page',
    method: 'get',
    params: query
  })
}

// 查询视频-类型(新增)详细
export function getTagtype(data) {
  return request({
    url: '/platform/video-type/detail',
    method: 'get',
    params: data
  })
}

// 新增视频-类型(新增)
export function addTagtype(data) {
  return request({
    url: '/platform/video-type/add',
    method: 'post',
    data: data
  })
}

// 修改视频-类型(新增)
export function updateTagtype(data) {
  return request({
    url: '/platform/video-type/edit',
    method: 'post',
    data: data
  })
}

// 删除视频-类型(新增)
export function delTagtype(data) {
  return request({
    url: '/platform/video-type/delete',
    method: 'post',
    data: data
  })
}
